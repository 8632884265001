<template>
  <div>
    <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
    <div class="mt-4">
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-btn elevation="2" @click="rank_attendees">Rank Attendees</v-btn>
        </v-col>
        <v-col>
          <v-btn
            elevation="2"
            @click="recalculate_score"
            :disabled="recalculating"
            >{{ recalculating ? "Processing..." : "Recalculate Score" }}</v-btn
          >
        </v-col>
        <v-container class="px-0" fluid>
          <v-col cols="6" sm="12" md="6">
            <v-switch
              v-model="switch1"
              label="Enable Rank Interval"
              @click="changeButtonState"
              readonly
            ></v-switch>
          </v-col>
        </v-container>
        <v-col>
          <AdminLayoutTable
            table_title="Users"
            :headers="headerProps"
            :items="items"
            v-on:edit_item="edit_item"
            v-on:delete_item="confirm_delete"
            item_key="user_data.email"
            single-select
          >
            <template v-slot:importer>
              <csv-importer-view
                button-title="User Point Distribution"
                item-type="Points"
                v-on:save-import="saveImport"
                :import_headers="import_headers"
              >
                <template v-slot:singleItemImporter>
                  <SingleUserCreator></SingleUserCreator>
                </template>
              </csv-importer-view>
            </template>
          </AdminLayoutTable>
        </v-col>
        <v-col>
          <AdminLayoutTable
            table_title="Points"
            :headers="headerProps"
            :items="items"
            v-on:edit_item="edit_item"
            v-on:delete_item="delete_item"
            item_key="user_data.email"
            single-select
          >
            <template v-slot:importer>
              <csv-importer-view
                :import_headers="import_headers"
                button-title="User Point Distribution"
                item-type="Points"
                v-on:save-import="saveImport"
              >
                <template v-slot:singleItemImporter>
                  <SingleUserCreator></SingleUserCreator>
                </template>
              </csv-importer-view>
            </template>
          </AdminLayoutTable>
        </v-col>
      </v-row>
      <user-editor
        ref="user_editor"
        :user-info="selected_user_for_edit"
      ></user-editor>
    </div>
  </div>
</template>

<script>
import AdminLayoutTable from "@/components/AdminLayoutTable";
import user_editor from "@/components/user_editor";
import CSVImporterView from "@/components/csvData/CSVImporterView";
import point_creator_vt from "@/components/point_creator_vt";
import user_creator from "@/components/user_creator";
import SingleUserCreator from "@/components/SingleUserCreator";
import { rtdb } from "@/firebase";
import { get_client_list, get_collection } from "@/modules/firebase_helpers";
import * as fb from "@/firebase";

export default {
  //headers are supplied as a list of objects
  //Text is the display name/text is mapped to the item(s) key { text: "name", value: "name" },

  name: "AdminPointManager",
  components: {
    AdminLayoutTable,
    "user-editor": user_editor,
    "csv-importer-view": CSVImporterView,
    SingleUserCreator
    // point_creator_vt,
    // user_creator
  },
  props: {},
  data() {
    return {
      selected: [],
      dialog: false,
      formTitle: "My form title",
      tab: "tab data",
      headerProps: [
        { text: "First Name", value: "user_data.first_name" },
        { text: "Last Name", value: "user_data.last_name" },
        { text: "Username", value: "user_data.username" },
        // { text: "Additional Data", value: "user_data.additionaldata" },
        // { text: "Email", value: "user_data.email" },
        { text: "Attendee ID", value: "user_data.event_attendee_id" },
        { text: "EF Email", value: "user_data.event_email" },
        // { text: "UID", value: "user_data.uid" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      search: "",
      editedItem: {},
      items_per_page: 20,
      sort_key: "last_name",
      selected_index: -1,
      selected_user_for_edit: null,
      last_seen: null,
      switch1: false,
      updateInterval: 0,
      recalculating: false,
      import_headers: [
        {
          slug: "event_email",
          required: true
        },
        {
          slug: "title",
          required: true,
          tooltip: `e.g. attendance++na++na++na
          The point title to import into the attendee record. You can get this title from the points page.
          DO NOT TRY TO CREATE THIS TITLE ON YOUR OWN!
          `
        },
        {
          slug: "type",
          required: true,
          tooltip: `e.g. trivia
          This "Type" field is used in analytics. Give it a value that will be used by the analytics report.
          `
        }
      ]
    };
  },
  methods: {
    fetch_users() {
      // console.log(this.sort_key);
      this.$store.dispatch("fetchUsers", {
        orderBy: "user_data." + this.sort_key,
        items_per_page: this.items_per_page
      });
    },
    changeButtonState() {
      rtdb
        .ref("io_panel")
        .child(this.$store.state.client_selection)
        .child("rank_interval_active")
        .set(!this.switch1);
    },
    rank_attendees() {
      this.$store.dispatch("rank_attendees");
    },
    close() {},
    editItem() {
      console.log("Running from parent");
    },
    save() {},
    process_data() {},
    process_import() {},
    initialize() {},
    edit_item(userObj) {
      console.log("running edited item", userObj);
      this.selected_user_for_edit = userObj;
      this.dialog = true;
      this.$nextTick(function() {
        this.$refs.user_editor.show_modal();
      });
    },
    saveUser() {},
    delete_item() {},
    saveBatchUsers() {},
    saveImport(csvData) {
      let data = {
        data: csvData,
        field_value_type: "union"
      };
      console.log("Point manager is delivering points");
      this.$store.dispatch("add_points_to_user", data);
    },

    runInterval() {
      this.rank_attendees();
      setTimeout(this.setTime, this.updateInterval * 1000);
    },
    recalculate_score: async function() {
      this.recalculating = true;
      let targetCollection = get_collection(
        this.$store.state.client_selection,
        "users"
      );
      const fbFunc = fb.fbFunctions.httpsCallable("RecalculatePoints");

      let all_users = await targetCollection.get();
      let user_ids = [];
      all_users.forEach(doc => {
        user_ids.push(doc.id);
      });
      let errors = [];

      for (let i = 0; i < user_ids.length; i++) {
        let payload = {
          uid: user_ids[i],
          customerCode: this.$store.state.client_selection
        };
        try {
          const res = await fbFunc(payload);
          if (res.data !== "success") {
            errors.push([user_ids[i], res]);
          }
        } catch (error) {
          errors.push([user_ids[i], error]);
        }
      }
      console.log("Errors during recalculation: ", errors);
      this.recalculating = false;
    },
    async confirm_delete(item, selection) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        this.delete_item(item, selection);
      }
    },
    set_fb_connection() {
      rtdb
        .ref("io_panel")
        .child(this.$store.state.client_selection)
        .child("rank_interval_active")
        .on("value", snapshot => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            this.switch1 = data;
          } else {
            rtdb
              .ref("io_panel")
              .child(this.$store.state.client_selection)
              .child("rank_interval_active")
              .set(false);
          }
        });
    }
  },
  computed: {
    allItems() {
      return this.$store.state.user_list;
    },
    items() {
      return this.$store.state.user_list;
    }
  },
  created() {
    this.fetch_users();
    this.set_fb_connection();
    this.$store.watch(
      state => {
        return this.$store.state.client_selection;
      },
      (newValue, oldValue) => {
        this.fetch_users();
        this.set_fb_connection();
      },
      {
        deep: true
      }
    );
  }
};
</script>
<style scoped></style>
